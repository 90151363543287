import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { LEGACY_APP_DOMAIN } from 'config';
import { fetchPost } from 'lib/apiHelpers';
import { ACTION_STATUSES, GROUP_MEMBERSHIP_ACTION_OPTIONS } from 'lib/constants';
import { GroupMembershipPropType, GroupPropType } from 'lib/propTypes';
import { UPDATE_CURRICULUM_ACCESS, REMOVE_PARTICIPANT } from 'store/groupMemberships/actions';
import Confirmation from 'components/shared/Modal/Confirmation';
import List from 'components/shared/ActionsMenu/List';
import Dropdown from './Dropdown';
import IndigenousWelcomeLetterAction from './IndigenousWelcomeLetterAction';
import InsufficientParticipation from './InsufficientParticipation';

export const resendWelcomePackage = (userId, gmId) => fetchPost(`/api/users/${userId}/group_memberships/${gmId}/resend_welcome_pack`, {});
export const resendRegistrationConfirmation = (userId, gmId) => fetchPost(`/api/users/${userId}/group_memberships/${gmId}/resend_registration_confirmation`, {});

function GroupMembershipActionsMenu({ groupMembership, group, options, type }) {
  const currentUser = useSelector((state) => state.currentUser);
  const dispatch = useDispatch();
  const [confirmation, setConfirmation] = useState({ open: false, close: () => {} });
  const ActionsMenu = type === 'dropdown' ? Dropdown : List;

  const showActions = useMemo(() => Object.values(GROUP_MEMBERSHIP_ACTION_OPTIONS).reduce((r, c) => {
    let action = options.includes(c);

    switch (c) {
      case GROUP_MEMBERSHIP_ACTION_OPTIONS.WELCOME: // fallthrough
      case GROUP_MEMBERSHIP_ACTION_OPTIONS.CONFIRMATION:
        action = action && ACTION_STATUSES.includes(groupMembership.completion);
        break;
      case GROUP_MEMBERSHIP_ACTION_OPTIONS.IWL:
        action = action && (group.state !== 'closed');
        break;
      case GROUP_MEMBERSHIP_ACTION_OPTIONS.REMOVE:
        action = action && (group.state !== 'closed' && groupMembership.completion !== 'completed' && groupMembership.completion !== 'removed');
        break;
      case GROUP_MEMBERSHIP_ACTION_OPTIONS.CERTIFICATE:
        action = action && groupMembership.certificateAvailable && groupMembership.completion === 'completed';
        break;
      case GROUP_MEMBERSHIP_ACTION_OPTIONS.PARE:
        action = action && currentUser?.isRegistrar && groupMembership.canResendPare;
        break;
      case GROUP_MEMBERSHIP_ACTION_OPTIONS.CURRICULUM_REVIEWER:
        action = action && currentUser?.hasFacilitatorAccess;
        break;
      case GROUP_MEMBERSHIP_ACTION_OPTIONS.INSUFFICIENT_PARTICIPATION:
        action = action && currentUser?.hasFacilitatorAccess;
        break;
      default:
      // no default
    }

    return ({ ...r, [c]: action });
  }, {}), [options, groupMembership, group.state, currentUser?.isRegistrar, currentUser?.hasFacilitatorAccess]);

  const handleConfirmModalClose = useCallback(() => {
    setConfirmation((prev) => ({ ...prev, open: false }));
  }, []);

  const handleResendWelcome = useCallback(() => {
    setConfirmation({
      open: true,
      close: handleConfirmModalClose,
      text: 'Are you sure you want to resend the welcome pack?',
      confirmText: 'Yes, send',
      confirmAction: () => resendWelcomePackage(groupMembership.userId, groupMembership.id),
      cancelText: 'No, don’t send',
    });
  }, [groupMembership.id, groupMembership.userId, handleConfirmModalClose]);

  const handleResendConfirmation = useCallback(() => {
    setConfirmation({
      open: true,
      close: handleConfirmModalClose,
      text: 'Are you sure you want to resend the registration confirmation?',
      confirmText: 'Yes, send',
      confirmAction: () => resendRegistrationConfirmation(groupMembership.userId, groupMembership.id),
      cancelText: 'No, don’t send',
    });
  }, [groupMembership.id, groupMembership.userId, handleConfirmModalClose]);

  const handleRemoveParticipant = useCallback(() => {
    setConfirmation({
      open: true,
      close: handleConfirmModalClose,
      title: 'Remove from group?',
      text: 'Are you sure you want to remove the participant from the group? They will be marked as removed and will no longer be able to participate in the course.',
      confirmText: 'Remove',
      cancelText: false,
      confirmAction: () => dispatch(REMOVE_PARTICIPANT.request({
        id: groupMembership.id,
        userId: groupMembership.userId,
      })),
    });
  }, [dispatch, groupMembership.id, groupMembership.userId, handleConfirmModalClose]);

  // TODO: implement resend PARE
  const handleResendPare = useCallback(() => {
    setConfirmation({
      open: true,
      close: handleConfirmModalClose,
      text: 'Are you sure you want to resend the PARE email?',
      confirmText: 'Yes, send',
      confirmAction: () => console.log(`Resending PARE to GM ${groupMembership.id}`), // eslint-disable-line no-console
      cancelText: 'No, don’t send',
    });
  }, [groupMembership.id, handleConfirmModalClose]);

  const handleUpdateReviewerAccess = useCallback(() => {
    setConfirmation({
      title: groupMembership.isCurriculumReviewer
        ? 'Remove reviewer access'
        : 'Add reviewer access',
      open: true,
      close: handleConfirmModalClose,
      text: groupMembership.isCurriculumReviewer
        ? 'Are you sure you want to remove reviewer access? This will prevent the participant from reading the course material without time limits and quiz completion.'
        : 'Are you sure you want to add reviewer access? This will allow the participant to read the course material without time limits and quiz completion.',
      confirmText: groupMembership.isCurriculumReviewer
        ? 'Remove reviewer access'
        : 'Add reviewer access',
      confirmAction: () => dispatch(UPDATE_CURRICULUM_ACCESS.request({
        id: groupMembership.id,
        userId: groupMembership.userId,
        groupMembership: {
          healthAuthorityId: groupMembership.healthAuthorityId, // required by the API
          isCurriculumReviewer: !groupMembership.isCurriculumReviewer,
        },
      })),
      cancelText: false,
      delay: 800,
    });
  }, [groupMembership.id, groupMembership.userId, groupMembership.isCurriculumReviewer, groupMembership.healthAuthorityId, handleConfirmModalClose, dispatch]);

  const menuItems = useMemo(() => {
    const items = [];

    if (Object.values(showActions).every((action) => !action)) {
      items.push({
        label: 'No actions available',
        as: 'span',
        className: 'text-muted text-nowrap',
      });
    }

    if (showActions[GROUP_MEMBERSHIP_ACTION_OPTIONS.WELCOME]) {
      items.push({
        label: 'Resend welcome pack',
        onClick: handleResendWelcome,
        icon: 'envelope',
      });
    }

    if (showActions[GROUP_MEMBERSHIP_ACTION_OPTIONS.IWL]) {
      items.push(<IndigenousWelcomeLetterAction key={`iwl-${groupMembership.id}`} id={groupMembership.id} />);
    }

    if (showActions[GROUP_MEMBERSHIP_ACTION_OPTIONS.CONFIRMATION]) {
      items.push({
        label: 'Resend registration confirmation',
        onClick: handleResendConfirmation,
        icon: 'personCheck',
      });
    }

    if (showActions[GROUP_MEMBERSHIP_ACTION_OPTIONS.CERTIFICATE]) {
      items.push({
        label: 'Generate certificate',
        href: `${LEGACY_APP_DOMAIN}/groups/${group.id}/group_memberships/${groupMembership.id}/edit`,
        icon: 'bookmarkCheck',
      });
    }

    if (showActions[GROUP_MEMBERSHIP_ACTION_OPTIONS.PARE]) {
      items.push({
        label: 'Resend PARE link',
        onClick: handleResendPare,
      });
    }

    if (showActions[GROUP_MEMBERSHIP_ACTION_OPTIONS.CURRICULUM_REVIEWER]) {
      items.push({
        label: `${groupMembership.isCurriculumReviewer ? 'Remove' : 'Add'} Reviewer Access`,
        onClick: handleUpdateReviewerAccess,
        icon: groupMembership.isCurriculumReviewer ? 'lock' : 'unlock',
        description: groupMembership.isCurriculumReviewer
          ? 'This participant has reviewer access'
          : 'Provide this participant with access to review the curriculum',
      });
    }

    if (showActions[GROUP_MEMBERSHIP_ACTION_OPTIONS.REMOVE]) {
      items.push({
        label: 'Remove from group',
        onClick: handleRemoveParticipant,
        icon: 'dashCircle',
        description: 'This will remove the participant from the group and invalidate their participation',
      });
    }

    if (showActions[GROUP_MEMBERSHIP_ACTION_OPTIONS.INSUFFICIENT_PARTICIPATION]) {
      items.push(<InsufficientParticipation key={`ip-${groupMembership.id}`} gm={groupMembership} />);
    }

    if (showActions[GROUP_MEMBERSHIP_ACTION_OPTIONS.IMPERSONATE]) {
      items.push({
        label: 'Impersonate participant',
        href: `${LEGACY_APP_DOMAIN}/user_impersonations/${groupMembership.userId}/create`,
      });
    }

    return items;
  }, [showActions, handleResendWelcome, groupMembership, handleResendConfirmation, group.id, handleResendPare, handleUpdateReviewerAccess, handleRemoveParticipant]);

  return (
    <>
      <ActionsMenu items={menuItems} />

      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Confirmation {...confirmation} />
    </>
  );
}

GroupMembershipActionsMenu.defaultProps = {
  group: {},
  groupMemberships: [],
  type: 'dropdown',
};

GroupMembershipActionsMenu.propTypes = {
  groupMembership: GroupMembershipPropType.isRequired,
  group: GroupPropType,
  groupMemberships: PropTypes.arrayOf(GroupMembershipPropType),
  options: PropTypes.arrayOf(PropTypes.oneOf(Object.values(GROUP_MEMBERSHIP_ACTION_OPTIONS))).isRequired,
  type: PropTypes.oneOf(['dropdown', 'list']),
};

export default GroupMembershipActionsMenu;
