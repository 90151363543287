import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GroupPropType } from 'lib/propTypes';
import { UPDATE_REVIEW } from 'store/groupShow/actions';
import Confirmation from 'components/shared/Modal/Confirmation';
import List from 'components/shared/ActionsMenu/List';
import ChangeGroupState from './ChangeGroupState';

function GroupSettingsActionsMenu({ group }) {
  const [confirmation, setConfirmation] = useState({ open: false, close: () => { } });
  const dispatch = useDispatch();
  const [changeGroupStateOpen, setChangeGroupStateOpen] = useState(false);

  // Handlers
  const handleConfirmModalClose = useCallback(() => {
    setConfirmation((prev) => ({ ...prev, open: false }));
  }, []);

  const handleUpdateReviewGroupFunctionality = useCallback(() => {
    setConfirmation({
      title: 'Review Group Functionality',
      open: true,
      close: handleConfirmModalClose,
      text: group.review
        ? 'Are you sure you want to remove review group functionality?'
        : 'Are you sure you want to add review group functionality?',
      confirmText: group.review
        ? 'Unmark As Review Group'
        : 'Mark As Review Group',
      confirmAction: () => dispatch(UPDATE_REVIEW.request({
        group_id: group.id,
        review: !group.review,
      })),
      cancelText: false,
      delay: 800,
    });
  }, [group.review, group.id, handleConfirmModalClose, dispatch]);

  const menuItems = useMemo(() => {
    const items = [];

    items.push({
      label: `${group.review ? 'Remove' : 'Add'} Reviewer Access`,
      onClick: handleUpdateReviewGroupFunctionality,
      icon: group.review ? 'lock' : 'unlock',
      description: group.review
        ? 'This group has review functionality'
        : 'Provide this group with review functionality',
    });

    if (group.state !== 'closed') {
      items.push({
        label: 'Change state',
        onClick: () => setChangeGroupStateOpen(true),
        icon: 'boxArrowUpRight',
        description: 'Advance the state of the group',
      });
    }

    return items;
  }, [group.review, group.state, handleUpdateReviewGroupFunctionality]);

  return (
    <>
      <List items={menuItems} />

      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Confirmation {...confirmation} />

      <ChangeGroupState
        setChangeGroupStateOpen={setChangeGroupStateOpen}
        changeGroupStateOpen={changeGroupStateOpen}
        group={group}
      />
    </>
  );
}

GroupSettingsActionsMenu.defaultProps = {
  group: {},
};

GroupSettingsActionsMenu.propTypes = {
  group: GroupPropType,
};

export default GroupSettingsActionsMenu;
