import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { ACTION_STATUSES, GROUP_MEMBERSHIP_ACTION_OPTIONS, REDUX_STATUS } from 'lib/constants';
import { useWorkspace } from 'lib/hooks/useWorkspace';
import { GroupMembershipPropType, UserPropType } from 'lib/propTypes';
import { LOAD_LOG_ENTRIES } from 'store/groupMemberships/actions';
import { RESET_LETTER_MESSAGE } from 'store/indigenousWelcomeLetters/actions';
import { Alert, Spinner as RBSpinner } from 'react-bootstrap';
import AlertDismissible from 'components/shared/AlertDismissible';
import JournalAndDiscussionBoards from 'components/users/JournalAndDbs/JournalAndDiscussionBoards';
import Spinner from 'components/shared/Spinner';
import GroupMembershipActionsMenu from 'components/users/GroupMembershipActionsMenu';
import GroupMembershipForm from 'components/users/GroupMembershipForm';
import LogsTable from 'components/users/LogsTable';
import CourseProgress from 'components/group_memberships/CourseProgress';
import ParticipantStatus from 'components/group_memberships/ParticipantStatus';
import GroupLink from 'components/group_memberships/GroupLink';
import GroupDropDown from 'components/group_memberships/GroupDropDown';

function GroupMembershipsTabPage({ user, groupMemberships }) {
  const currentUser = useSelector((state) => state.currentUser);
  const { alertMessage } = useSelector((state) => state.indigenousWelcomeLetters);
  const { path: workspacePath } = useWorkspace();
  const { params } = useRouteMatch(`${workspacePath}/people/:id/group_memberships/:groupMembershipId`) || {};

  const logEntries = useSelector((state) => state.groupMemberships.logEntries);
  const dispatch = useDispatch();

  const latestGroupMembership = groupMemberships.data?.find((gm) => [...ACTION_STATUSES, 'completed'].includes(gm.completion)) ?? groupMemberships.data?.[0];
  const gm = params?.groupMembershipId ? groupMemberships.data?.find((d) => d.id === Number(params.groupMembershipId)) : latestGroupMembership;

  const dismissIwlAlert = useCallback(() => {
    dispatch(RESET_LETTER_MESSAGE.action());
  }, [dispatch]);

  useEffect(() => {
    if (gm && currentUser?.isRegistrar) {
      dispatch(LOAD_LOG_ENTRIES.request({ id: gm.id }));
    }
  }, [gm, currentUser?.isRegistrar, dispatch]);

  return (
    <>
      <div className={groupMemberships.data?.length > 0 ? 'bg-light px-4 py-2' : ''}>
        {groupMemberships.data?.length > 1 && (
          <GroupDropDown gm={gm} groupMemberships={groupMemberships} />
        )}

        {groupMemberships.data?.length === 1 && (
          <GroupLink group={gm?.group} />
        )}
      </div>

      {groupMemberships.status === REDUX_STATUS.PENDING && <Spinner overlay />}

      {alertMessage && (
        <div className="mt-3 px-4">
          <AlertDismissible onDismiss={dismissIwlAlert}>
            {alertMessage}
          </AlertDismissible>
        </div>
      )}

      {(user && groupMemberships.status !== REDUX_STATUS.PENDING && !gm) && (
        <div className="mt-3 px-4">
          <Alert variant="info">{`${user?.fullName ?? '...'} is not registered in any groups.`}</Alert>
        </div>
      )}

      {gm && (
        <div className="mb-4 px-4">
          <ParticipantStatus gm={gm} />

          <div className="row">
            <div className="col-md-8 col-xl-9">
              <GroupMembershipForm groupMembership={gm} />
            </div>

            <div className="col">
              {(currentUser?.hasFacilitatorAccess) && (
                <div className="mt-3 mt-md-0 mb-3 border rounded py-2">
                  <h4 className="py-2 px-3">Actions</h4>

                  <ul className="list-unstyled">
                    <GroupMembershipActionsMenu
                      type="list"
                      groupMembership={gm}
                      group={gm.group}
                      groupMemberships={groupMemberships.data}
                      options={[
                        GROUP_MEMBERSHIP_ACTION_OPTIONS.WELCOME,
                        GROUP_MEMBERSHIP_ACTION_OPTIONS.IWL,
                        GROUP_MEMBERSHIP_ACTION_OPTIONS.CONFIRMATION,
                        GROUP_MEMBERSHIP_ACTION_OPTIONS.CERTIFICATE,
                        // GROUP_MEMBERSHIP_ACTION_OPTIONS.PARE,
                        GROUP_MEMBERSHIP_ACTION_OPTIONS.CURRICULUM_REVIEWER,
                        GROUP_MEMBERSHIP_ACTION_OPTIONS.REMOVE,
                        GROUP_MEMBERSHIP_ACTION_OPTIONS.INSUFFICIENT_PARTICIPATION,
                      ]}
                    />
                  </ul>
                </div>
              )}
              {(currentUser?.hasFacilitatorAccess) && (
                <div className="mt-3 mt-md-0 mb-3 border rounded py-2">
                  <h4 className="py-2 px-3">Journals & DBs</h4>
                  <JournalAndDiscussionBoards
                    groupMembershipId={gm.id}
                    subGroupId={gm.subGroupId}
                    groupId={gm.groupId}
                    userId={gm.userId}
                  />
                </div>
              )}
            </div>
          </div>

          <CourseProgress gm={gm} />

          {currentUser?.isRegistrar && (
            <div className="my-4">
              <h4>
                Logs
                {logEntries.status === REDUX_STATUS.PENDING && <RBSpinner size="sm" className="ms-1" animation="border" role="status" />}
              </h4>
              <p>Events recorded by the platform.</p>

              {logEntries.status === REDUX_STATUS.SUCCESS && (
                <LogsTable logEntries={logEntries} />
              )}

              {logEntries.status === REDUX_STATUS.ERROR && (
                <Alert variant="warning">
                  <span className="fas fa-exclamation-triangle fa-fw me-1" />
                  Unable to load event log
                </Alert>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}

GroupMembershipsTabPage.defaultProps = {
  user: {},
};

GroupMembershipsTabPage.propTypes = {
  user: UserPropType,
  groupMemberships: PropTypes.shape({
    data: PropTypes.arrayOf(GroupMembershipPropType),
    status: PropTypes.oneOf(Object.values(REDUX_STATUS)),
  }).isRequired,
};

export default GroupMembershipsTabPage;
