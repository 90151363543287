import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { stringToNull } from 'lib/utils';
import { GroupPropType } from 'lib/propTypes';
import { CHANGE_GROUP_STATE } from 'store/groupShow/actions';
import { Button, Spinner } from 'react-bootstrap';
import DefaultModal from 'components/shared/Modal/DefaultModal';
import SelectField from 'components/shared/FormFields/SelectField';

const FormSchema = Yup.object().shape({
  groupId: Yup.number().required('Required'),
  stateEvent: Yup.string().required('Required'),
});

function ChangeGroupState({ group, setChangeGroupStateOpen, changeGroupStateOpen }) {
  const dispatch = useDispatch();

  const initialValues = {
    groupId: group.id,
    stateEvent: null,
  };

  const handleSave = (values, actions) => {
    dispatch(CHANGE_GROUP_STATE.request(stringToNull(values), { formikActions: actions }));
    setChangeGroupStateOpen(false);
  };

  const handleClose = (resetForm) => {
    setChangeGroupStateOpen(false);
    if (typeof resetForm === 'function') resetForm();
  };

  return (
    <Formik
      onSubmit={handleSave}
      validationSchema={FormSchema}
      enableReinitialize
      initialValues={initialValues}
    >
      {({ isSubmitting, handleSubmit, resetForm }) => (
        <DefaultModal
          size="md"
          isOpen={changeGroupStateOpen}
          header="Change group state"
          onClose={() => handleClose(resetForm)}
          footerComponent={(
            <Button value="primary" onClick={handleSubmit}>
              Update state
              {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
            </Button>
          )}
        >
          <Form key={group.id}>
            <SelectField
              name="stateEvent"
              label="Advance group to"
              options={group.availableStateTransitions}
              includeBlank="Choose state"
            />
            <p>This action will advance the group to this state and will trigger of all of associated emails and setup that is required. </p>
          </Form>
        </DefaultModal>
      )}
    </Formik>
  );
}

ChangeGroupState.defaultProps = {
  group: {},
};

ChangeGroupState.propTypes = {
  group: GroupPropType,
  changeGroupStateOpen: PropTypes.bool.isRequired,
  setChangeGroupStateOpen: PropTypes.func.isRequired,
};

export default ChangeGroupState;
