import { asyncAction, syncAction } from 'lib/actionCreatorCreators';

export const LOAD_GROUP_MEMBERSHIPS = asyncAction('LOAD_GROUP_MEMBERSHIPS');
export const LOAD_GROUP_MEMBERSHIP = asyncAction('LOAD_GROUP_MEMBERSHIP');
export const LOAD_GROUP_MEMBERSHIPS_FORM_DATA = asyncAction('LOAD_GROUP_MEMBERSHIPS_FORM_DATA');
export const UPDATE_GROUP_MEMBERSHIP = asyncAction('UPDATE_GROUP_MEMBERSHIP');
export const UPDATE_CURRICULUM_ACCESS = asyncAction('UPDATE_CURRICULUM_ACCESS');
export const REMOVE_PARTICIPANT = asyncAction('REMOVE_PARTICIPANT');
export const UPDATE_INSUFFICIENT_PARTICIPATION = asyncAction('UPDATE_INSUFFICIENT_PARTICIPATION');
export const RESET_GROUP_MEMBERSHIPS = syncAction('RESET_GROUP_MEMBERSHIPS');
export const LOAD_LOG_ENTRIES = asyncAction('LOAD_LOG_ENTRIES');
export const UPDATE_MARKED_AS_INDIGENOUS = asyncAction('UPDATE_MARKED_AS_INDIGENOUS');
export const RESEND_IWL = asyncAction('RESEND_IWL');
export const GET_IWL_STATUS = asyncAction('GET_IWL_STATUS');
export const LOAD_JOURNAL_AND_DISCUSSION_BOARDS = asyncAction('LOAD_JOURNAL_AND_DISCUSSION_BOARDS');
